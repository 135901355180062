// Допуск по виткам
const XS = 0.1
const S = 0.25
const M = 0.5
const L = 1

const TURNS = [
  [S, S, XS, XS, XS, XS, XS, XS],
  [S, M, S, S, S, XS, XS, XS],
  [M, M, S, S, S, S, S, XS],
  [L, M, S, M, M, S, S, S],
  [XS, 0.07, 0.04, 0.05, 0.05, 0.04, 0.04, 0.025]
]

const TURNS_RANGE = {
  byTurns: [6.4, 10.1, 16.1, 25.1],
  byDWire: [0.31, 0.61, 1.41, 3.1, 6.1, 12.1, 25.1]
}

// Для типа "Пружины сжатия"
const TURNS_TYPE1 = [
  [0.25, 0.175, 0.1, 0.125, 0.125, 0.1, 0.1, 0.075],
  [0.375, 0.25, 0.15, 0.25, 0.25, 0.15, 0.15, 0.1],
  [0.75, 0.5, 0.25, 0.375, 0.375, 0.25, 0.25, 0.15],
  [1.125, 0.75, 0.375, 0.5, 0.5, 0.375, 0.375, 0.25],
  [0.1, 0.07, 0.04, 0.05, 0.05, 0.04, 0.04, 0.025]
]

const TURNS_RANGE_TYPE1 = {
  byDWire: [0.31, 0.61, 1.41, 3.1, 6.1, 12.1, 25.1, 50.1],
  byTurns: [6.3, 10.1, 16.1, 25.1]
}

export {
  TURNS,
  TURNS_RANGE,
  TURNS_TYPE1,
  TURNS_RANGE_TYPE1
}
