const TAP_LENGTH = [
  0.6, 0.75, 0.9, 1.1, 1.3, 1.6, 1.9, 2.2, 2.5, 2.9, 3.2, 3.6, 4.0
]

const TAP_LENGTH_RANGE = [
  3.1, 6.1, 10.1, 18.1, 30.1, 50.1, 80.1, 120.1, 180.1, 250.1, 315.1, 400.1
]

export {
  TAP_LENGTH,
  TAP_LENGTH_RANGE
}
