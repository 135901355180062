const BODY_LENGTH = [
  [0.032, 0.045, 0.055, 0.16, 0.32, 0.5, 1, 1.6],
  [0.036, 0.052, 0.065, 0.18, 0.36, 0.6, 1.1, 1.8],
  [0.045, 0.06, 0.075, 0.22, 0.45, 0.7, 1.4, 2.2],
  [0.055, 0.08, 0.095, 0.26, 0.55, 0.9, 1.8, 2.6],
  [0.075, 0.1, 0.13, 0.36, 0.75, 1.2, 2.4, 3.6],
  [0.1, 0.15, 0.18, 0.5, 1, 1.6, 3.2, 5],
  [0.15, 0.21, 0.26, 0.7, 1.5, 2.4, 4.8, 7]
]

const BODY_LENGTH_RANGE = {
  byS3D: [0.4, 0.63, 1, 1.6, 2.5, 4.1],
  byDWire: [0.31, 0.61, 1.5, 3.1, 6.1, 12.1, 25.1]
}

const BODY_LENGTH_359 = [
  '+0.1/-0.3',
  '+0.15/-0.4',
  '+0.25/-0.5',
  '+0.3/-0.75',
  '+0.5/-1',
  '+0.75/-1.5',
  '+1/-2',
  '+1,5/-3',
  '+2/-4',
  '+2.5/-5',
  '+3/-6'
]

const BODY_LENGTH_RANGE_359 = {
  byLength: [16, 30, 50, 75, 100, 150, 200, 300, 400, 500]
}

// Для типа "Пружины растяжения"
const WIRE_DIAMETER_TOLERANCE = [0.02, 0.025, 0.04, 0.08, 0.16, 0.24, 0.6, 0.8]

const BODY_LENGTH_RANGE_TYPE2 = {
  byDWire: [0.31, 0.61, 1.41, 3.1, 6.1, 12.1, 25.1, 50.1],
  byTurns: [6.3, 10.1, 16.1, 25.1]
}

const BODY_LENGTH_TYPE2 = [
  [0.25, 0.175, 0.1, 0.125, 0.125, 0.1, 0.1, 0.075],
  [0.375, 0.25, 0.15, 0.25, 0.25, 0.15, 0.15, 0.1],
  [0.75, 0.5, 0.25, 0.375, 0.375, 0.25, 0.25, 0.15],
  [1.125, 0.75, 0.375, 0.5, 0.5, 0.375, 0.375, 0.25],
  [0.1, 0.07, 0.04, 0.05, 0.05, 0.04, 0.04, 0.025]
]

export {
  BODY_LENGTH,
  BODY_LENGTH_RANGE,
  BODY_LENGTH_359,
  BODY_LENGTH_RANGE_359,
  WIRE_DIAMETER_TOLERANCE,
  BODY_LENGTH_RANGE_TYPE2,
  BODY_LENGTH_TYPE2
}
