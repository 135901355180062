import { POSITION_FORMULAS } from '@/utils/production/position/formula'

const mixinPositionCalc = {
  computed: {
    calcStiffness () {
      return POSITION_FORMULAS.stiffness({
        dWire: this.position?.d_wire,
        dOuter: this.position?.d_outer_1,
        turns: this.position?.number_turns,
        material: this.position.material?.name
      })
    }
  }
}

export {
  mixinPositionCalc
}
