const DIAMETER = [
  [0.12, 0.15, 0.18, 0.48, 0.48, 1.4, 3.6, 4.8],
  [0.15, 0.19, 0.22, 0.6, 0.6, 1.8, 4.5, 6],
  [0.18, 0.24, 0.28, 0.75, 0.75, 2.2, 5.5, 7.5],
  [0.24, 0.3, 0.36, 0.96, 0.95, 2.8, 7, 9.5],
  [0.48, 0.36, 0.45, 1.2, 1.2, 3.4, 9, 11.5],
  [0.96, 0.42, 0.5, 1.3, 1.3, 3.8, 11, 13.5],
  [1.28, 0.48, 0.57, 1.5, 1.5, 4.3, 13, 16],
  [1.64, 0.54, 0.64, 1.7, 1.7, 4.8, 15, 19]
]

const DIAMETER_RANGE = {
  byDWire: [0.36, 0.7, 1.6, 3.4, 7, 14, 28],
  byIndex: [5.1, 6.3, 8.1, 10.1, 12.1, 14.1, 18.1]
}

export {
  DIAMETER,
  DIAMETER_RANGE
}
