const CLEARANCE_TOLERANCE_RANGE = {
  byDWire: [0.51, 1.31, 2.11, 3.11, 4.41, 6.41, 8]
}

const CLEARANCE_TOLERANCE = [
  '0.25',
  '0.5',
  '0.7',
  '1',
  '1.5',
  '2',
  '3',
  '4'
]

export {
  CLEARANCE_TOLERANCE_RANGE,
  CLEARANCE_TOLERANCE
}
