// получение индекса в диапазоне для таблиц
function getIndexInRange (val, numArray) {
  for (let i = 0; i < numArray.length; i++) {
    if (val < numArray[i]) return i
  }
  return numArray.length
}
export {
  getIndexInRange
}
