import { TURNS, TURNS_RANGE, TURNS_TYPE1, TURNS_RANGE_TYPE1 } from '@/const/production/position/tableLimit/turnsLimit'
import { DIAMETER, DIAMETER_RANGE } from '@/const/production/position/tableLimit/diameterLimit'
import {
  BODY_LENGTH,
  BODY_LENGTH_RANGE,
  BODY_LENGTH_359,
  BODY_LENGTH_RANGE_359,
  WIRE_DIAMETER_TOLERANCE,
  BODY_LENGTH_RANGE_TYPE2,
  BODY_LENGTH_TYPE2
} from '@/const/production/position/tableLimit/bodyLengthLimit'
import { TAP_LENGTH, TAP_LENGTH_RANGE } from '@/const/production/position/tableLimit/tapLengthLimit'
import { CLEARANCE_TOLERANCE_RANGE, CLEARANCE_TOLERANCE } from '@/const/production/position/tableLimit/clearanceToleranceLimit'

const POSITION_TABLE_LIMIT = {
  BODY_LENGTH,
  BODY_LENGTH_RANGE,
  BODY_LENGTH_359,
  BODY_LENGTH_RANGE_359,
  DIAMETER,
  DIAMETER_RANGE,
  TAP_LENGTH,
  TAP_LENGTH_RANGE,
  TURNS,
  TURNS_RANGE,
  TURNS_TYPE1,
  TURNS_RANGE_TYPE1,
  WIRE_DIAMETER_TOLERANCE,
  BODY_LENGTH_RANGE_TYPE2,
  BODY_LENGTH_TYPE2,
  CLEARANCE_TOLERANCE_RANGE,
  CLEARANCE_TOLERANCE
}

export {
  POSITION_TABLE_LIMIT
}
